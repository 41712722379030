import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import LayoutPage from "../components/LayoutPage"
import SEO from "../components/seotagcat"
import { SlugLink } from '../components/utils'
import moment from "moment";


class Pages extends React.Component {
  render() {
    const page = this.props.data.privateGraphql.getpage
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    // const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    // const { previous, next } = this.props.pageContext
    return (
      <LayoutPage location={this.props.location} title={siteTitle}>
        <SEO
          title={page.metatitle}
          description={page.short}
          image={`${page.image}`}
          isBlogPost={true}
          datePublished={page.publishDate}
        />
        <article className="archive-post mts-post spp" style={{ maxWidth: 1200 }}>
          <header className="post-content">
            <div className="post-meta">
              <h1 className="w-article-header__headline">{page.title}</h1>
            </div>
          </header>
          <div className="post-entry">
            <section dangerouslySetInnerHTML={{ __html: page.detail }} />
          </div>
          <footer>
          </footer>
        </article>
      </LayoutPage>
    )
  }
}

export default Pages

export const pageQuery = graphql`
  query ($slugtitle: String = "404", $siteId: Int = 1078) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getpage(slugtitle: $slugtitle, siteId: $siteId) {
        id
        detail
        image
        metadescription
        metatitle
        publishDate
        short
        title
        slugtitle
      }
    }
  }
`
